<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    :mini-variant="expandOnHover"
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-list-item style="background: white;">
      <v-list-item-content>
        <div class="logo-mini">
          <v-img src="@/assets/logo_mini.png" />
        </div>
        <div class="logo-normal">
          <router-link to="/">
            <v-img src="@/assets/logo.png" />
          </router-link>
        </div>
      </v-list-item-content>
    </v-list-item>

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
          @click.native="closeOthers(i)"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/',
          active: false,
        },
        {
          icon: 'mdi-account-multiple-plus',
          title: 'leads',
          to: '/leads',
          active: false,
        },
        {
          icon: 'mdi-shield-account',
          title: 'clients',
          to: '/clients',
          active: false,
        },
        {
          icon: 'mdi-shield-remove-outline',
          title: 'archives',
          to: '/archives',
          active: false,
        },
        {
          icon: 'mdi-counter',
          title: 'policies',
          to: '/policies',
          active: false,
        },
        {
          icon: 'mdi-folder',
          title: 'documents',
          to: '/documents',
          active: false,
        },
        {
          icon: 'mdi-poll',
          title: 'reports',
          to: '/reports',
          active: false,
        },
        {
          group: '',
          icon: 'mdi-home-group',
          title: 'team',
          active: false,
          children: [
            {
              to: 'agencies',
              title: 'agencies',
              icon: 'mdi-umbrella',
            },
            {
              to: 'locations',
              title: 'locations',
              icon: 'mdi-map-marker-star',
            },
            {
              to: 'agents',
              title: 'agents',
              icon: 'mdi-face-agent',
            },
            {
              to: 'carriers',
              title: 'carriers',
              icon: 'mdi-bank',
            },
            {
              to: 'finances',
              title: 'finance',
              icon: 'mdi-piggy-bank',
            },
          ],
        },
        {
          icon: 'mdi-map-marker',
          title: 'map',
          to: '/map',
          active: false,
        },
        {
          group: '',
          icon: 'mdi-account',
          title: 'profile',
          active: false,
          children: [
            {
              to: 'profile/change-password',
              title: 'changePassword',
              icon: 'mdi-lock',
            },
          ],
        },
      ],
    }),

    computed: {
      ...mapState({
        barColor: state => state.sidebar.barColor,
        barImage: state => state.sidebar.barImage,
      }),
      drawer: {
        get () {
          return this.$store.state.sidebar.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },

    mounted () {
      this.$nextTick(() => {
        /**
         * fix app sidebar disappearing issue on mobile
         */
        (() => {
          const target = document.getElementById('core-navigation-drawer')
          target.style.height = '100%'
          const observer = new MutationObserver(mutations => {
            const drawerStyles = target.style
            if (drawerStyles.transform === 'translateX(-100%)' && this.$vuetify.breakpoint.mdAndUp) {
              this.setDrawer(false)
              this.setDrawer(true)
            }
          })
          observer.observe(target, { attributes: true, attributeFilter: ['style'] })
        })()
      })
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },

      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

      closeOthers (val) {
        this.items = this.items.map((item, i) => {
          if (val !== i) {
            return { ...item, active: false }
          } else {
            return { ...item, active: true }
          }
        })
      },
    },
  }
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-navigation-drawer__content::-webkit-scrollbar
    display: none

  .v-navigation-drawer__content
    -ms-overflow-style: none
    scrollbar-width: none
  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
  .logo-normal
    max-width: 60%
    margin: auto
    transition: all 250ms linear
  .logo-mini
    opacity: 0
    max-width: 34px
    height: 34px
    margin: auto
    overflow: hidden
    border-radius: 50%
    position: absolute
    transition: all 250ms linear

.v-navigation-drawer--mini-variant
  .logo-normal
    opacity: 0 !important
    position: absolute
  .logo-mini
    opacity: 1 !important
    position: initial !important
</style>
